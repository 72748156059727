/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #333;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  background-color: #f7f7f7;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', serif;
  margin-bottom: 15px;
}

p {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 15px;
  font-size: 1rem;
}

/* Responsive */
h1 {
  font-size: 3rem;
}

p {
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.5rem;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.6rem;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 1.5rem;
    margin: 15px;
  }

  p {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}
