.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-form {
    padding: 20px 20px;
    border-radius: 8px;
    max-width: 900px;
    width: 100%;
    backdrop-filter: blur(1px);

  }
  
  .contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
  
  }
  
  /* Grille à trois colonnes pour écran large */
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    gap: 20px;
    margin-bottom: 3%;
  }
  
  /* Groupes de champs */
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    color: #333;
    outline: none;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    backdrop-filter: blur(2px);

  }
  
  textarea {
    min-height: 100px;
  }
  
  .form-group input:focus,
  .form-group select:focus,
  .form-group textarea:focus {
    box-shadow: inset 0 1px 5px rgba(255, 255, 255, 0.4);
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 5px;
    background-color: #01796F;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #556B2F;
  }
  
  .success-message {
    color: #00ff00;
    text-align: center;
    margin-top: 20px;
  }
  
  
  /* Passer à une seule colonne à partir de 1024px */
  @media (max-width: 1024px) {
    .form-grid {
      grid-template-columns: 1fr 1fr; /* Deux colonnes sur tablette */
      gap: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .form-grid {
      grid-template-columns: 1fr; /* Une seule colonne sur petits écrans */
    }
  
    .contact-form {
      padding: 20px;
    }
  
    .form-group input,
    .form-group select,
    .form-group textarea {
      padding: 8px;
    }
  
    button[type="submit"] {
      width: 100%;
    }
  
    .form-right {
      margin-top: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .contact-form {
      padding: 20px;
    }
  
    .form-group input,
    .form-group select,
    .form-group textarea {
      padding: 8px;
    }
  }
  