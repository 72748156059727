.apropos-container {
  padding: 50px;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  max-width: 80vw;
}

/* Conteneur de présentation et d'intervention */
.presentation-container, .intervention-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  gap: 30px;
}

/* Contenu textuel */
.text-content {
  flex: 1;
  padding-right: 20px;
  align-self: center;
}

/* Image de présentation et localisation */
.apropos-image, .loca-image {
  width: 30vw;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Titres */
.apropos-container h1 {
  color: #333;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.apropos-container h2 {
  color: #333;
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 15px;
}

/* Texte */
.apropos-container p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #333;
}

/* Liens */
.apropos-container a {
  color: #F4F4F4;
  text-decoration: none;
}

.apropos-container a:hover {
  text-decoration: underline;
}

/* Conteneur pour les partenariats */
.partnership-container {
  text-align: center;
  margin-bottom: 30px;
}

/* Conteneur pour les logos */
.logos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

/* Style pour les logos des partenaires */
.partner-logo {
  width: 120px;
  height: auto;
  object-fit: contain;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.partner-logo:hover {
  filter: none;
}

/* Centrage des images */
.image-container {
  align-self: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .presentation-container, .intervention-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .apropos-container {
    padding: 0px;
  }

  .intervention-container {
    flex-direction: column-reverse;
  }

  .text-content {
    padding: 0 15px;
  }

  .apropos-image, .loca-image {
    width: 100%;
    max-width: 300px;
  }

  .logos-container {
    flex-direction: row;
    gap: 15px;
  }

  .partner-logo {
    width: 90px;
  }

  /* Réduction de la taille des titres */
  .apropos-container h1 {
    font-size: 1.6rem;
  }

  .apropos-container h2 {
    font-size: 1.4rem;
  }

  .apropos-container p {
    font-size: 1rem;
  }
}
