.service-container {
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto; 
  color: #fff;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.service-container h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.service-container p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 40px; 
}

/* Carte de chaque service */
.service-item {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

/* Image du service */
.service-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Titre du service */
.service-item h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
}

/* Texte de description du service */
.service-item p {
  font-size: 1rem;    font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

/* Responsive */
@media (max-width: 768px) {
  .service-container h1 {
      font-size: 2rem;
  }

  .services-list {
      grid-template-columns: 1fr; /* Une seule colonne sur mobile */
  }

  .service-item img {
      height: 150px; 
  }

  .service-item h2 {
      font-size: 1.5rem;
  }

  .service-item p {
      font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .service-container {
      padding: 10px; 
  }

  .service-container h1 {
      font-size: 1.8rem;
      margin: 0;
  }

  .service-item img {
      height: 120px;
  }

  .service-item p {
    font-size: 1rem;
  }
}
