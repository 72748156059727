.social-container {
  position: fixed;      
  bottom: 10px;
  right: 20px;
  width: 80px; 
  height: 80px;
  z-index: 999;
  overflow: visible; 
}

.social-container.hovered .social-icon.instagram {
  transform: translate(-10px, -30px); 
  transition: transform 0.3s ease 0.1s;
}
.social-container.hovered .social-icon.facebook {
  transform: translate(-30px, -10px); 
  transition: transform 0.3s ease 0.1s;
}

.social-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: #f1f1f1;
  color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  font-size: 24px;
  transition: transform 0.3s ease; 
  pointer-events: none;  
}

.social-icon:hover {
  transform: scale(1.1);
}

.instagram {
  background-color: #e1306c;
  color: white;
  top: 10px;
  right: -10px;
}
.facebook {
  background-color: #3b5998;
  color: white;
  top: 30px;
  right: 10px;
}

.social-container.hovered .social-icon {
  pointer-events: auto;
}

/* =============== Responsive =============== */
@media (max-width: 768px) {
  .social-container {
    top: 0px;
    right: 16%;
    width: 66px;
    height: 70px;
  }
 
  .instagram {
    top: 15%;
    left: 5%;
  }
  
  .facebook {
    top: 40%;
    left: 15%;
  }
  
 .phone {
    background-color: #26ab6f;
    color: white;
    top: 35%;
    left: 45%;
  }
  
  .mail {
    background-color: #d05f5f;
    color: white;
    top: 10%;
    left: 35%;
  }
  
  .social-icon {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
  

  .social-container.hovered .social-icon.instagram {
    transform: translate(-35px, 45px); 
    transition: transform 0.3s ease 0.1s;
  }
  .social-container.hovered .social-icon.facebook {
    transform: translate(-10px, 45px); 
    transition: transform 0.3s ease 0.1s;
  }

  .social-container.hovered .social-icon.phone {
    transform: translate(5px, 48px);
    transition: transform 0.3s ease 0.1s;
  }
  .social-container.hovered .social-icon.mail {
    transform: translate(45px, 52px);
    transition: transform 0.3s ease 0.1s;
  }
}
