body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.App {
  text-align: center;
}

.Navbar {
  padding: 20px;
}

/* Quand l'aside est activé, la page principale est réduite */
.App.with-aside .main-container {
  margin-left: 25%;
  transition: margin-left 0.6s ease-in-out;
}

.main-container {
  transition: margin-left 0.6s ease-in-out;
  position: relative;
  z-index: 1;
}

/* Ajustements pour les appareils mobiles */
@media (max-width: 768px) {
  .App.with-aside .main-container {
    margin-left: 0;
  }
  .main-container {
    padding: 50px 5px 0 5px;
  }

  body {
    background-image: url('./assets/fond12.webp');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
}
