.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding-top: 60px;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  display: flex;
}


.lightbox-image {
  width: 100%;
  height: auto;
  max-height: 80vh;
  margin: 0px;
  border-radius: 13px;
}

.close {
  position: absolute;
  right: 5px;
  top: -10px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 30px;
}

.prev, .next {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  color: white;
  font-size: 50px;
  cursor: pointer;
  user-select: none;
}

.prev {
  left: 2%;
  top: 50%;
  background-color: black;
  padding: 0px 10px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: bolder;
}

.next {
  right: 2%;
  top: 50%;
  background-color: black;
  padding: 0px 10px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: bolder;
}

@media (max-width: 768px) {
  .modal-content {
    background: linear-gradient(145deg, #1f1f1f, #292929);
    color: #fff;
    width: 90%;
    max-width: 800px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.8);
    animation: modal-appear 0.3s ease;
    text-align: center;
    margin-top: 20%;
    height: 80%;
  }
  
  .modal-image {
    background-size: cover;
    background-position: center;
    height: 33%;
  }
  
  .modal-body {
    padding: 20px;
    height: 66%;
    overflow: auto;
  }
  
    .modal-nav.prev {
      position: absolute;
      left: 3%;
      top: 40%;
      font-size: 5vw;
      padding: 3px 10px;
    }
    
    .modal-nav.next {
      position: absolute;
      right: 3%;
      top: 40%;
      font-size: 5vw;
      padding: 3px 8px;
    }
}