/* Slide out - Page sortante glisse vers la gauche */
.slide-exit {
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

/* Slide in - Page entrante glisse depuis la droite */
.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 0.5s ease;
}
