.hero-container {
  position: relative;
  background-image: url('../../assets/fond12.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

h5 {
  margin: 0;
}

.footer {
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 90%;
  gap: 20px;
}

.logocontainer {
  width: 333px;
}

.hero-overlay {
  z-index: 2;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.6s ease-in-out;
  background-color: rgba(255, 255, 255, 0);
  position: fixed;
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
  padding-top: 60px;
}

.hero-logo {
  width: 100%;
  height: auto;
  transition: all 0.6s ease-in-out;
}

/* Styles pour le mode aside */
.hero-container.aside .hero-overlay {
  position: fixed;
  width: 20%;
  background-color: rgba(255, 255, 255, 0.2);
  height: 100%;
  z-index: 2;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.6s ease-in-out;
  padding-bottom: 7%;
  padding-top: 60px;
}

.hero-container.aside .hero-overlay .footer {
  gap: 5px;
  bottom: 20px;
}

/* Logo dans l'aside */
.hero-logo.logo-aside {
  width: 66%;
  height: auto;
  margin: 0;
  transition: all 0.6s ease-in-out;
}

.cta-button {
  background-color: #01796f;
  transition: background-color 0.6s ease-in-out;
  color: white;
  padding: 15px 40px;
  font-size: 1.2rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 10px 0 20px 0;
}

.cta-button:hover {
  background-color: #556b2f;
}

/* Styles pour les titres et paragraphes */
.hero-container h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin: 5px;
  transition: font-size 0.6s ease-in-out;
}

.hero-container h2 {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  margin: 5px;
  transition: font-size 0.6s ease-in-out;
}

.hero-container p {
  font-size: 1.2rem;
  text-align: center;
  margin: 10px 10px;
  transition: font-size 0.6s ease-in-out;
}

/* Ajustements pour l'aside */
.hero-container.aside h1 {
  font-size: 1.4rem;
  margin: -2% 2%;
}

.hero-container.aside h2 {
  font-size: 1.1rem;
  margin: 2%;
}

.hero-container.aside p {
  font-size: 1rem;
  margin: 2%;
}

.hero-container.aside .cta-button {
  padding: 8px 20px;
  font-size: 1rem;
  margin: auto 2% 20% 2%;
}

/* En aside, le contenu de la page doit se décaler */
.main-container.with-aside .page-content {
  margin: 80px 0 0 20%;
  transition: opacity 0.6s ease-in-out;
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;
}

/* Comportement normal du contenu de la page */
.page-content {
  opacity: 0;
  margin-left: 0;
  position: relative;
  z-index: 1;
}

/* Responsive pour mobile */
@media (max-width: 768px) {
  .hero-container {
    height: 100vh;
  }

  .hero-overlay {
    position: relative;
    width: 100%;
    padding-top: 70px;
    height: 100vh;
    padding-bottom: 0;
  }

  .hero-logo,
  .hero-logo.logo-aside {
    width: 77%;
  }

  .hero-container h1 {
    font-size: 1.5rem;
    margin: -5px 5px 0 5px;
  }

  .hero-container h2 {
    font-size: 1.2rem;
    margin: 5px 10px;
  }

  .hero-container p {
    font-size: 0.9rem;
    margin: 5px 15px;
  }

  .cta-button {
    font-size: 18px;
    padding: 15px 20px;
    font-weight: bold;
    margin: 15px;
  }

  /* Ajustements pour que le HeroSection ne devienne pas un aside sur mobile */
  .hero-container.aside {
    display: none;
  }

  .main-container.with-aside .page-content {
    margin: 80px 0 0 0;
  }

  .page-content {
    opacity: 1;
    margin-top: 10vw;
  }

  .footer {
    gap: 5px;
    position: relative;
    margin-top: 30px;
    margin-bottom: -10px;
  }

  .footer a {
    margin: 0 5px;
  }
}