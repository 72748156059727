.navbar {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  backdrop-filter: blur(1px);
  height: 70px;
  max-height: 70px;
}

a {
  text-decoration: none;
}

/* Logo de la navbar */
.navbar-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-logo-text {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  width: max-content;
}

/* Texte de la page active (visible uniquement en desktop) */
.navbar-page-name {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  display: none; /* Masqué en mobile, visible en desktop */
}

/* Logo de la navbar pour mobile */
.navbar-logo-link {
  display: none;
}

.navbar-logo-image {
  height: 30px;
  width: auto;
}

/* Bouton "Demandez un Devis" (caché par défaut) */
.navbar-cta-link {
  display: none;
}

/* Menu burger (caché par défaut) */
.burger-menu {
  display: none;
}

/* Menu principal */
.navbar-menu {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.navbar-menu li {
  position: relative;
  text-align: center;
}

.navbar-menu a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  font-weight: bold;
  position: relative;
}

/* Effet de soulignement des liens */
.navbar-menu a::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 50%;
  background-color: white;
  transition: width 0.3s ease, left 0.3s ease;
}

.navbar-menu a:hover::before {
  width: 100%;
  left: 0;
}

/* --- Responsive --- */
@media (max-width: 768px) {
  /* Ajustement de la navbar */
  .navbar {
    padding: 20px 3vh;
    justify-content: center;
    display: flex;
  }
  
  .navbar-left {
    display: flex;
    width: 100%;
    justify-content: left;
  }

  /* Afficher le texte du logo sur la page d'accueil, masquer sur les autres pages */
  .navbar-logo-text {
    display: block;
    font-size: 18px;
    align-self: start;
  }
  
  .navbar-logo-link {
    display: block;
  }

  /* Masquer le texte de la page active en version mobile */
  .navbar-page-name {
    display: none;
  }

  /* Afficher le bouton "Demandez un Devis" sur les autres pages */
  .navbar-cta-link {
    display: block;
    width: fit-content;
    position: absolute;
  }

  .navbar-cta-button {
    background-color: #01796F;
    color: white;
    padding: 2vw 10px;
    font-size: 12px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    width: 25vw;
    font-weight: bolder;
    margin-top: -5px;
  }

  .navbar-cta-button:hover {
    background-color: #556B2F;
  }

  /* Afficher le menu burger */
  .burger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .navbar-menu a {
    color: #ddd;
  }

  .burger-bar {
    width: 100%;
    height: 3px;
    background-color: #333;
    transition: all 0.3s ease;
  }

  .burger-bar.open:nth-child(1) {
    transform: rotate(45deg) translateY(14px);
  }

  .burger-bar.open:nth-child(2) {
    opacity: 0;
  }

  .burger-bar.open:nth-child(3) {
    transform: rotate(-45deg) translateY(-15px);
  }

  /* Masquer le menu principal par défaut */
  .navbar-menu {
    display: none;
  }

  /* Afficher le menu principal lorsqu'il est ouvert */
  .navbar-menu.open {
    display: flex;
    position: fixed;
    top: 65px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    gap: 30px;
    padding: 40px;
    opacity: 1;
    width: fit-content;
  }

  .navbar-menu li {
    margin: 0;
    text-align: center;
  }
}

/* --- Affichage en desktop --- */
@media (min-width: 769px) {
  .navbar.is-homepage .navbar-left {
    width: 20%;
  }

  .navbar:not(.is-homepage) .navbar-left {
    width: 20%;
  }

  .navbar-page-name {
    display: inline;
    font-size: 1.1em;
  }
}

.navbar-logo-image {
  position: absolute;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  height: 70px;
}