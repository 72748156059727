.blog-container {
  padding: 40px;
}

.blog-container h1 {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.posts-grid {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.post {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.post img {
  width: 100%;
  height: 66vh;
  border-radius: 8px;
  object-fit: cover;
}

.post p {
  color: #333;
  margin: 10px 0;
  font-size: 1em;
}

.post a {
  text-decoration: none;
  color: #ff6b6b;
  font-weight: bold;
}

@media (max-width: 768px) {
  .blog-container {
    padding: 0px;
  }
}