.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(8px); 
    transition: backdrop-filter 0.3s ease;
  }
  
  .modal-content {
    background: linear-gradient(145deg, #1f1f1f, #292929); 
    color: #fff;
    width: 90%;
    max-width: 800px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.8); 
    animation: modal-appear 0.3s ease; 
    text-align: center;
    margin-top: 30px;
  }
  
  @keyframes modal-appear {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .modal-image {
    height: 300px;
    background-size: cover;
    background-position: 0px -115px;
  }
  
  .modal-close {
    position: absolute;
    top: 0px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    color: white;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .modal-close:hover {
    transform: scale(1.2);
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .modal-body h2 {
    margin-top: 0;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    color: #F4F4F4;
  }
  
  .modal-body p {
    margin-top: 10px;
    line-height: 1.8;
    font-size: 1rem;
    color: #ddd;
  }
  
  .modal-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-nav {
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #fff;
  }
  
  
  .modal-nav.prev {
    position: absolute;
    left: 5px; 
    top: 53%;
    padding: 3px 8px;
  }
  
  .modal-nav.next {
    position: absolute;
    right: 5px;
    top: 53%;
    padding: 3px 8px;
  }
  
  .modal-nav:focus {
    outline: none;
  }
  