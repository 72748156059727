.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: 200px;
  gap: 20px;
  margin: 20px;
  transition: all 0.5s ease-in-out;
}

.gallery-page-container h1 {
  font-size: 2rem;
  color: #333;
  margin-top: 30px;
}

/* Carte d'image avec un effet asymétrique */
.gallery-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: transform 0.3s ease, opacity 0.5s ease;
  opacity: 1;
  transform: scale(1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Différentes tailles d'images pour le rendu asymétrique */
.gallery-item:nth-child(3n) {
  grid-column: span 2;
  grid-row: span 2;
}

.gallery-item:nth-child(5n) {
  grid-column: span 1;
  grid-row: span 2;
}

/* Image à l'intérieur de chaque carte */
.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Zoom sur l'image au survol */
.gallery-item:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .overlay {
  opacity: 1;
}

.overlay h3 {
  font-size: 1.5rem;
  text-align: center;
  color: #ddd;
}

/* Filtres */
.filter-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;
}

label {
  display: flex;
  gap: 10px;
}

/* Animation de disparition des éléments non sélectionnés */
.gallery-item.hide {
  opacity: 0;
  transform: scale(0.5);
  pointer-events: none;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.gallery-item.abs-pos {
  position: absolute;
}

.gallery-item.show {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
  transition: opacity 0.5s ease, transform 0.5s ease;
}
